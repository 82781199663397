/** @see https://laravel.com/docs/11.x/vite#blade-processing-static-assets */
import.meta.glob(["../images/**", "../video/**"]);

/** Packages. */
import "instant.page";

/** Modules. */
import checkIn from "./app/modules/checkIn.js";
import cookieConsent from "./app/modules/cookieConsent.js";
import { loadAmmap } from "./app/modules/helpers.js";
import mouseflowStart from "./app/modules/mouseflowStart.js";
import setupAlpineAndLivewire from "./app/modules/setupAlpineAndLivewire.js";
import setupAxios from "./app/modules/setupAxios.js";
import setupCash from "./app/modules/setupCash.js";
import setupEventId from "./app/modules/setupEventId.js";
import trackPageView from "./app/modules/tpv.js";
import updateViewportHeightProperty from "./app/modules/updateViewportHeightProperty.js";

/** Ammap loader helper. TODO: refactor usages to async Alpine components. */
window.loadAmmap = loadAmmap;

/** Initialise packages & modules (Alpine.js & Livewire must start after other packages). */
setupEventId();

setupAxios();

setupCash();

setupAlpineAndLivewire();

checkIn()
  .then(() => {
    trackPageView(window.rlEventId);
  })
  .then(() => {
    /** Dispatch a custom event on window, for testing. */
    window.dispatchEvent(new Event("app:track-page-view-completed"));

    if (window.rlCheckInCountryCode) {
      mouseflowStart(window.rlCheckInCountryCode);
    }
  })
  .catch((error) => {
    console.error(error);
  });

updateViewportHeightProperty();

cookieConsent();

/** Import legacy filter functions partial. */
import "./app/partials/filter.js";
