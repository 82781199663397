export default function checkIn() {
  return window.axios.post(route("ajax.check-in")).then(function (response) {
    return new Promise((resolve) => {
      /** @var {CheckInData} data */
      let data = response.data;

      window.rlCheckInCountryCode = data.countryCode;

      /**
       * Retrieve the fresh CSRF token and replace statically cached invalid tokens.
       * @see https://github.com/statamic/cms/blob/5.x/src/StaticCaching/Cachers/FileCacher.php#L204 Similar technique in Statamic.
       */
      const csrfToken = data.csrfToken;

      /** Axios CSRF token. */
      window.axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

      /** Livewire CSRF token. */
      window.livewireScriptConfig["csrf"] = csrfToken;

      /** Update form token hidden input value. */
      document.querySelectorAll('input[name="_token"]').forEach(function (csrfTokenInput) {
        csrfTokenInput.setAttribute("value", csrfToken);
      });

      document.querySelectorAll('meta[name="csrf-token"]').forEach(function (csrfTokenMeta) {
        csrfTokenMeta.setAttribute("content", csrfToken);
      });

      /**
       * Dispatch an event to Livewire.
       * @see App\Livewire\NewsletterSignupFormSection::$listeners
       */
      window.Livewire.dispatch("check-in-completed");

      resolve();
    });
  });
}
