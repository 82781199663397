/**
 * Filename is the acronym of 'track page view' to bypass ad-blockers.
 *
 * @param {string} eventId
 * @return {Promise<axios.AxiosResponse<any>>}
 */
export default function trackPageView(eventId) {
  /** @type {TrackPageViewRequestData} */
  const trackPageViewRequestData = {
    url: window.location.href,
    event_uuid: eventId,
    document_referrer: document.referrer,
    document_title: document.title,
  };

  return window.axios.post(route("ajax.page-view"), trackPageViewRequestData);
}
